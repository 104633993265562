import React from "react";
import { Container, Row, Col } from 'react-bootstrap';
import ReactMarkdown from "react-markdown/with-html"
import NewsletterForm from "./forms/newsletter-form";
import InvestorForm from "./forms/investor-form";
import InvestorGuideForm from "./forms/investors-guide-form";
import SalesForceForm from "./forms/salesforce-form"
import PropertyManchesterTeam from "./PropertyManchesterTeam";
import BlogPageSidebar from "./forms/blogpage-sidebar";
import Breadcrumbs from "../components/breadcrumbs";
import { useStaticQuery, graphql } from "gatsby";
import Subscribe from "../images/subscribe.png";
import Img from 'gatsby-image';
import { Helmet } from "react-helmet";
import { useLocation } from "@reach/router";

//import TeamDetails from "./get-office-team";

const InnerDetails = (props) => {
  const data = useStaticQuery(graphql`
  query JoinnewsletterQuery2 {
    strapiGlobalConfig {
      Join_Our_Newsletter_Sidebar_Content
      ServicePage_Newsletter_Sidebar_Content
    }
}
`)
  var guide_url = '';

  if (props.InvestorsGuide && props.InvestorsGuide[0]) {
    guide_url = props.InvestorsGuide && props.InvestorsGuide[0].Upload_Guide.internal.description ? props.InvestorsGuide[0].Upload_Guide.internal.description.replace("File ", "").replace('"', '').replace('"', '') : '';
  }

  const location = useLocation();
  const thePath = location.pathname
  // {Modules.ManchesterTitle !== null && Modules.ManchesterTeam !== null &&
  //   <p>sssss</p>
  //    }

  return (
    <>
    {/* <TeamDetails /> */}
    <section className="static-content sample">
      {props?.CustomClass === "investors-guide" && 
        <div className='about-text landing-intro'>
            <Container>
              <div className="news-head text-center 456"  >
                {props.pagetitle !="Reviews" &&
                  <Breadcrumbs/>
                }
                <h1>{props.Title}</h1>
              </div>          
            </Container>
           
          </div>}

      <Container>
        {/* static block1 start */}
        {props.CustomClass == "our-awards-page" &&
          <div classname="awardstext"><ReactMarkdown source={props.AwardsContent} escapeHtml={false} /></div>
        }
        <Row>
          <Col xl={8}>
            {/* static text start */}
            <div className="static-text">
              <div className="static-text-wrap">
                {props?.ManchesterData?.length > 0 &&
                <PropertyManchesterTeam {...props?.ManchesterData[0]}/>
                }
                <ReactMarkdown source={props.Content} escapeHtml={false} />
                {props.CustomClass == "our-awards-page" &&
                  <div className="awards-content">
                    <div className="awards-top-wrap">
                      <Img className="awardimgdesktop" objectFit="cover" fluid={props.AwardtopimageDesktop.childImageSharp.fluid} alt="Awardimage" />
                      <Img className="awardimgipad" objectFit="cover" fluid={props.AwardtopimageIpad.childImageSharp.fluid} alt="Awardimage" />
                      <Img className="awardimgmobile" objectFit="cover" fluid={props.AwardtopimageMobile.childImageSharp.fluid} alt="Awardimage" />
                    </div>
                    <h3>{props.AwardList[0].Awards_Main_Title}</h3>
                    <h6>{props.AwardList[0].Award_Sub_Title}</h6>
                    <ReactMarkdown source={props.AwardList[0].Awards_Content} escapeHtml={false} />
                    <h3>{props.AwardList[1].Awards_Main_Title}</h3>
                    <h6>{props.AwardList[1].Award_Sub_Title}</h6>
                    <ReactMarkdown source={props.AwardList[1].Awards_Content} escapeHtml={false} />
                    <div className="awards-top-wrap">
                      <Img className="awardimgdesktop" objectFit="cover" fluid={props.AwardbottomimageDesktop.childImageSharp.fluid} alt="Awardimage" />
                      <Img className="awardimgipad" objectFit="cover" fluid={props.AwardbottomimageIpad.childImageSharp.fluid} alt="Awardimage" />
                      <Img className="awardimgmobile" objectFit="cover" fluid={props.AwardbottomimageMobile.childImageSharp.fluid} alt="Awardimage" />
                    </div>
                  </div>

                }
              </div>
            </div>
            {/* static text end */}
          </Col>

          {props.Sidebar != false && props.CustomClass != "investor" && props.CustomClass != "investors-guide" &&
            <Col xl={4} className="d-xl-block">
              {props.CustomClass == "our-awards-page" &&
                <div className="awards-wrap">
                  <Img className="awardimgdesktop" objectFit="cover" fluid={props.AwardimageDesktop.childImageSharp.fluid} alt="Awardimage" />
                  <Img className="awardimgipad" objectFit="cover" fluid={props.AwardimageIpad.childImageSharp.fluid} alt="Awardimage" />
                  <Img className="awardimgmobile" objectFit="cover" fluid={props.AwardimageMobile.childImageSharp.fluid} alt="Awardimage" />
                </div>
              }
              {/* newsletter block start */}
              <div className="newsletter-wrap">
                <BlogPageSidebar Heading={data?.strapiGlobalConfig?.ServicePage_Newsletter_Sidebar_Content} Sidebar_News_Content={props?.Sidebar_News_Content}/>
                <NewsletterForm Heading={data.strapiGlobalConfig.Join_Our_Newsletter_Sidebar_Content} />
                <div className="d-lg-none subscribe text-center d-md-flex align-items-md-center justify-content-md-center animate__animated">
                  <img src={Subscribe} alt="img" />
                  <span>
                    <strong>Stay up to date </strong>
                    with our new videos by
                    <a href="https://www.youtube.com/channel/UCR0ob8Yam7B9tuG-81So3GA" target='_blank'> subscribing to our YouTube</a>
                  </span>
                </div>
              </div>
              {/* newsletter block end */}
            </Col>
          }
          {props.Sidebar != false && props.CustomClass == "investor" &&
            <Col xl={4} className="d-xl-block">
              {/* newsletter block start */}
              <div className="newsletter-wrap">
                {/* <InvestorForm Heading={data.strapiGlobalConfig.Join_Our_Newsletter_Sidebar_Content} /> */}
                <h2>JOIN OUR INVESTORS CLUB</h2>
                <p>If you would like a full list of our investments on offer, then please leave your contact details below.</p>
                <SalesForceForm className={"sidebar investor"} propertyName={"Investor"} retURL={process.env.GATSBY_SITE_URL+"/thank-you-investors-club"}/>
                <div className="d-lg-none subscribe text-center d-md-flex align-items-md-center justify-content-md-center animate__animated">
                  <img src={Subscribe} alt="img" />
                  <span>
                    <strong>Stay up to date </strong>
                    with our new videos by
                    <a href="https://www.youtube.com/channel/UCR0ob8Yam7B9tuG-81So3GA" target='_blank'> subscribing to our YouTube</a>
                  </span>
                </div>
              </div>
              {/* newsletter block end */}
            </Col>
          }
          {props.Sidebar != false && props.CustomClass == "investors-guide" &&
            <Col xl={4} className="d-xl-block">
              {/* newsletter block start */}
              <div className="newsletter-wrap">
                <InvestorGuideForm guide={guide_url} Heading={data.strapiGlobalConfig.Join_Our_Newsletter_Sidebar_Content} Title={props?.Static_Banner_Title[0]?.Static_Banner_Title} />
                <div className="d-lg-none subscribe text-center d-md-flex align-items-md-center justify-content-md-center animate__animated">
                  <img src={Subscribe} alt="img" />
                  <span>
                    <strong>Stay up to date </strong>
                    with our new videos by
                    <a href="https://www.youtube.com/channel/UCR0ob8Yam7B9tuG-81So3GA" target='_blank'> subscribing to our YouTube</a>
                  </span>
                </div>
              </div>
              {/* newsletter block end */}
            </Col>
          }
        </Row>
        {/* static block1 end */}
      </Container>
    </section>
    </>
  )
}
export default InnerDetails
