import React, { useState, useEffect } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import "animate.css/animate.css";
import GetThumbnailwithLink from "./get-thumbnail-link"

// import ScrollAnimation from 'react-animate-on-scroll';

const TileSection = (props)=>{

  const [] = useState(false)
  const [] = useState(0);
  useEffect(()=>{

    function isInViewport(el) {
        let top = el.offsetTop;
        let left = el.offsetLeft;
        let width = el.offsetWidth;
        let height = el.offsetHeight;

        while(el.offsetParent) {
          el = el.offsetParent;
          top += el.offsetTop;
          left += el.offsetLeft;
        }

        return (
          top < (window.pageYOffset + window.innerHeight) &&
          left < (window.pageXOffset + window.innerWidth) &&
          (top + height) > window.pageYOffset &&
          (left + width) > window.pageXOffset
        );

    }

    const box = document.querySelector('.properties');
    document.addEventListener('scroll', handleScroll, {
        passive: true
    });

    function handleScroll () {
    
        if(isInViewport(box)) {
          
          /** Here one can write animate.css class for animation **/

          box.classList.add('animate__fadeInUp','animate__slower');  



        } else {
          
          box.classList.remove('animate__fadeInUp','animate__slower');
      
        }
    }

    return () =>{
      document.addEventListener('scroll', handleScroll);
    }

  },[])

  return (<React.Fragment>
        <section className="section-properties landing-tiles d-md-block">
        {/* <ScrollAnimation animateIn="animate__fadeInUp" animateOnce={true} > */}
        <Container className="properties">

          <Row className="property-wrap">
          {props.data.map((Tile) => {
            return<>
          {Tile.Image &&
            <Col md="6" className="col-lg-4">
              <div className="property-block text-center">
                <GetThumbnailwithLink customlink={Tile.Custom_Link} link = {Tile.Tile_Link} img ={Tile.Image.childImageSharp.fixed} Title={Tile.Title}/>
              </div>
            </Col>
          }
            </>
          })}
            
          </Row>
        </Container>
        
        {/* </ScrollAnimation> */}
      </section>
      
  </React.Fragment>)

}

export default TileSection; 